import { useState } from "react";
import {
  Card,
  TextField,
  Container,
  Typography,
  Box,
  Button,
} from "@mui/material";
import EmojiPicker from "emoji-picker-react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import Swal from "sweetalert2";

function App() {
  const [replyTo, setReplyTo] = useState(null);
  const [tweet, setTweet] = useState("");
  const [tweetUrl, setTweetUrl] = useState("");

  const handleSubmit = async (e) => {
    let url = "https://twitter.com/intent/tweet?";
    if (replyTo) {
      url += `in_reply_to=${replyTo}&`;
    }
    url += `text=${encodeURIComponent(tweet)}`;
    setTweetUrl(url);
    window.open(url);
    try {
      await navigator.clipboard.writeText(url);
      console.log("Text copied to clipboard");
    } catch (err) {
      console.log("Failed to copy text: ", err);
    }
  };
  return (
    <Container maxWidth="sm">
      <Typography variant="h1" component="h1" align="center">
        Jack Space
      </Typography>
      <Card sx={{ p: 2, mt: 2 }}>
        <Typography variant="h5" component="h2" align="center">
          Fa tweet
        </Typography>
        <TextField
          label="Reply to"
          variant="outlined"
          type="number"
          fullWidth
          value={replyTo}
          sx={{ mb: 2 }}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={(e) => setReplyTo(e.target.value)}
        />

        <TextField
          label="Tweet"
          variant="outlined"
          fullWidth
          multiline
          value={tweet}
          onChange={(e) => {
            setTweetUrl("");
            setTweet(e.target.value);
          }}
          rows={4}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ mb: 2 }}
        />

        <TextField
          label="Tweet Url"
          variant="outlined"
          fullWidth
          multiline
          value={tweetUrl}
          InputLabelProps={{
            shrink: true,
          }}
          rows={4}
          readonly
          sx={{ mb: 2 }}
        />
        <Box sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
          <Button sx={{ mb: 3 }} variant="contained" onClick={handleSubmit}>
            Tweet
          </Button>
          <Box>
            {tweetUrl && (
              <CopyToClipboard
                onCopy={() => {
                  Swal.fire({
                    icon: "success",
                    title: "Copied!",
                    text: "Tweet url copied to clipboard",
                    showConfirmButton: false,
                    toast: true,
                    position: "top-end",
                    timer: 1500,
                  });
                }}
                text={tweetUrl}
              >
                <Button variant="contained">Copy to clipboard</Button>
              </CopyToClipboard>
            )}
          </Box>
        </Box>
      </Card>
    </Container>
  );
}

export default App;
